import { Button, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReactSVG } from 'react-svg';

export default function Shortcut({display_icons, item}) {
  const {name, text_color, icon_color, icon, category_names, subcategory_names} = item
  const categories = useSelector((state) => state?.menuFeed?.info?.product_data?.categories);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)

  const apiHost = sessionStorage.getItem('apiHost')

  const image_url = `${apiHost}/assets/${icon}`

  useEffect(() => {
    if (categories) {
      setLoading(false)
    }
  }, [categories])

  const handleClick = () => {
    let route = 'products'
    if(category_names.length > 0){
      route += `?category=${encodeURIComponent(category_names.join(','))}`
    }
    if(subcategory_names.length > 0){
      route += `?subcategory=${encodeURIComponent(subcategory_names.join(','))}`
    }
    navigate(route)
  }


  return (
    <Button
      loading={loading}
      size='large'
      icon={<ReactSVG src={image_url} alt={name} style={{width:20, fill: icon_color}}
      beforeInjection={(svg) => {
        svg.setAttribute('style', `fill: ${icon_color}`);
      }}
    />}
      onClick={handleClick}
      disabled={loading}
    >
      <Typography.Text style={{color: text_color}}>
        {name}
      </Typography.Text>
    </Button>
  )
}
