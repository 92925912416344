import { LINEAGE_DATA } from "../Constants";
import { isMedMenu } from "../utility/generalUtils";
import { notNull } from "../sharedFunctions";
import { isEmpty } from '../utility/generalUtils';

// Return an object with friendly names for the filters

export const filterNames = (activeFilters, product_data, sdpData) => {

  if (!product_data) {
    return {};
  }

  let subcategories = [];
  product_data?.categories?.forEach(cat => subcategories.push(...cat?.subcategories))

  const filterData = {
    brand: {
      data: product_data?.brands,
      match: "id",
      key: "name",
      display_name: "Brand",
    },
    category: {
      data: product_data?.categories,
      match: "safe_name",
      key: "name",
      display_name: "Category",
    },
    subcategory: {
      data: subcategories,
      match: "safe_name",
      key: "name",
      display_name: "Subcategory",
    },
    terpenes: {
      data: product_data?.terpenes,
      match: "id",
      key: "name",
      display_name: "Terpene",
    },
    lineage: {
      type: "Hash",
      data: LINEAGE_DATA,
      match: "hash",
      key: "display_name",
      display_name: "Lineage",
    },
    flavors: {
      data: product_data?.flavors,
      match: "id",
      key: "name",
      display_name: "Flavor",
    },
    effects: {
      data: product_data?.effects,
      match: "id",
      key: "name",
      display_name: "Effect",
    },
    strains: {
      data: product_data?.strains,
      match: "id",
      key: "name",
      display_name: "Strain",
    },
    product_type: {
      data: product_data?.product_types,
      match: "id",
      key: "name",
      display_name: "Product Type",
    },
    deals: {
      data: product_data?.discounts,
      match: "id",
      key: "name",
      display_name: "Deal",
    },
    search: {
      data: [],
      display_name: "Search",
    },
    price: {
      data: [],
      display_name: "Price",
    },
    weights: {
      data: [],
      display_name: "Weight",
    },
    thc_potency: {
      data: [],
      display_name: "THC Potency",
    },
    sdp_category: {
      data: sdpData,
      match: "sdp_category",
      key: "name",
      display_name: "SDP Category",
    }
  };

  const activeFilterNames = { primary: null, secondary: []};

  for( const filterType of Object.keys(activeFilters)) {
    const fd = filterData[filterType];
    if (!fd) {
      console.log(`No filter data found for ${filterType}`);
      break;
    }
    activeFilters[filterType].forEach((filterId) => {
      if (filterType === "search") {
        activeFilterNames[filterType] = {displayName: fd.display_name, filterType: filterType, itemDisplayName: filterId, id: filterId, title: `Display Results for '${filterId}'`};
        activeFilterNames.secondary.push( activeFilterNames[filterType]);
      } else if (["price", "weights", "thc_potency"].includes( filterType )) {
        activeFilterNames.secondary.push( {displayName: fd.display_name, filterType: filterType, id: filterId, itemDisplayName: filterId});
      }else if (fd.type === "Hash") {
        const filterItem = fd.data[filterId.replace(' ', '_')];
        if (filterItem) {
          activeFilterNames.secondary.push({
            displayName: fd.display_name,
            id: filterId,
            filterType: filterType,
            itemDisplayName: filterItem[fd.key],
          });
        }
      }
      else if (fd.data) {
        const filterItem = fd.data.find(
          (el) => el[fd.match].toString() === filterId.toString()
        );
        if (filterItem) {
          activeFilterNames.secondary.push({
            displayName: fd.display_name,
            id: filterId,
            filterType: filterType,
            itemDisplayName: filterItem[fd.key],
          });
        }
      }
    });
  };

  if (activeFilters["category"]) {
    if( activeFilters["category"].length < 2){
      activeFilterNames["title"] = "Category: ";
    } else {
      activeFilterNames["title"] = "Categories: ";
    }
    activeFilterNames["secondary"].forEach((item) => {
      if( item.filterType === "category") {
        activeFilterNames["title"] += item.itemDisplayName + ", ";
      }
    })
    activeFilterNames["title"] = activeFilterNames["title"].slice(0, -2);
  }else if (activeFilterNames["subcategory"]) {
    if( activeFilters["subcategory"].length < 2){
      activeFilterNames["title"] = "Subcategory: ";
    } else {
      activeFilterNames["title"] = "Subcategories: ";
    }
    activeFilterNames["secondary"].forEach((item) => {
      if( item.filterType === "subcategory") {
        activeFilterNames["title"] += item.itemDisplayName + ", ";
      }
    })
  } else if (activeFilterNames["search"]) {
    const search_string = activeFilterNames["search"].itemDisplayName;
    activeFilterNames["title"] = `Display Results for '${search_string}'`;
    // Remove Search item from secondary filters
    activeFilterNames.secondary = activeFilterNames.secondary.filter((item) => item.name !== "Search");
  }
  return activeFilterNames;
};

export const applyFilters = (product_data, activeFilters) => {
  console.log("applyFilters");
  const menu_items = product_data?.menu_items;
  if( isEmpty( activeFilters) || activeFilters.length === 0 ) return menu_items;
  if( activeFilters.length === 0 ) return menu_items;

  const weightTypeIds = getWeightTypeIds(product_data?.product_types);
  let subcategories = []
  product_data?.categories?.forEach(cat => subcategories.push(...cat?.subcategories))
  const filtered_products = [];
  menu_items?.forEach((org_product) => {
    // Make a copy of the original product so we don't modify the original
    // We may remove variants from the product.
    const product = structuredClone(org_product);

    // A filter should return false if the product should be filtered out.
    // If only some variants meet the criteria, the filter should return true and mark the variants that don't meet the criteria.
    if( Object.keys(activeFilters)?.every((filter) => {
      if (filter === "brand") {
        return activeFilters[filter]?.includes(product?.brand_id?.toString());
      }else if (filter === "category"){
        // For category, need to check subcategory as well and treat as an 'or'.
        const catResults = activeFilters[filter]?.some(item => product.category_ids?.map(cat_id => product_data?.categories?.find(cat => cat.id === cat_id)?.safe_name).includes(item));
        const subcatResults = activeFilters['subcategory'] ?
         activeFilters['subcategory']?.some(item => product.subcategory_ids?.map(subcat_id => subcategories?.find(subcat => subcat.id === subcat_id)?.safe_name).includes(item)) : false;
        const filter_result = catResults || subcatResults;
        if( product.variants?.length > 0 && filter_result ){
          product.variants = product.variants.filter(variant => {
            const variant_catResults = activeFilters[filter]?.some(item => variant.category_ids?.map(cat_id => product_data?.categories?.find(cat => cat.id === cat_id)?.safe_name).includes(item));
            const variant_subcatResults = activeFilters['subcategory'] ?
              activeFilters['subcategory']?.some(item => variant.subcategory_ids?.map(subcat_id => subcategories?.find(subcat => subcat.id === subcat_id)?.safe_name).includes(item)) : false;
            return variant_catResults || variant_subcatResults;
          });
        }
        return filter_result;
      }else if (filter === "subcategory"){
        // For subcategory, if there is also a category filter, it is handled there.
        if( activeFilters['category']){
          return true; // Handled in category filter
        } else {
          const filter_result = activeFilters[filter]?.some(item => product.subcategory_ids?.map(subcat_id => subcategories?.find(subcat => subcat.id === subcat_id)?.safe_name).includes(item));
          if( product.variants?.length > 0 && filter_result ){
            product.variants = product.variants.filter(variant => activeFilters[filter]?.some(item => variant.subcategory_ids?.map(subcat_id => subcategories?.find(subcat => subcat.id === subcat_id)?.safe_name).includes(item)));
          }
          return filter_result;
        }
      }else if (filter === "product_type") {
        return activeFilters[filter]?.includes(product?.product_type_id?.toString());
      }else if (filter === "terpenes") {
        return activeFilters[filter]?.some(item => product.terpene_ids.includes(Number(item)));
      }else if (filter === "flavors") {
        return activeFilters[filter]?.some(item => product.flavor_ids.includes(Number(item)));
      }else if (filter === "effects") {
        return activeFilters[filter]?.some(item => product.effect_ids.includes(Number(item)));
      }else if (filter === "strains") {
        return activeFilters[filter]?.includes(product.strain_id?.toString());
      }else if (filter === "weights") {
        const filter_result = weightTypeIds.includes(product?.product_type_id) && product?.variants.some((variant) => activeFilters[filter]?.includes(variant.display_weight));
        if( product.variants?.length > 0 && filter_result ){
          product.variants = product.variants.filter(variant => activeFilters[filter]?.includes(variant.display_weight));
        }
        return filter_result;
      }else if (filter === 'thc_potency'){
        const thcCheck = (prod, range) => {
          if( prod.test_results?.thc?.unit_type !== '%') return false;

          switch (range) {
            case 'Low (1-10%)':
              return (prod.test_results.thc.current < 10 && prod.test_results.thc.current > 0);
            case 'Medium (10%-20%)':
              return prod.test_results.thc.current >= 10 && prod.test_results.thc.current < 20;
            case 'High (>20%)':
              return prod.test_results.thc.current >= 20;
            default:
              return false;
          }
        }
        return activeFilters[filter]?.some(range => thcCheck(product, range));
      }else if (filter === "price") {
        const priceRange = (sku) => {
          let price
          if( isMedMenu()){
            price = sku?.discounted_med_price_cents || sku?.med_price_cents
          }else{
            price = sku?.discounted_price_cents || sku?.price_cents
          }
          if( price < 2000) return 'Under $20';
          if( price >= 2000 && price < 4000) return '$20-$40';
          if( price >= 4000 && price < 6000) return '$40-$60';
          if( price >= 6000 && price < 8000) return '$60-$80';
          if( price >= 8000) return '$80 & Above';
          return 'Not Found';
        }
        product.variants = product.variants.filter(variant => activeFilters[filter]?.includes(priceRange(variant)));
        return product.variants.length > 0;
      }else if(filter === 'deals'){
        // Need to check each deal id in the activeFilters[filter] array against the deal_ids of the variants
        product.variants = product.variants.filter(variant => activeFilters[filter]?.some(dealId => variant.best_discount_ids.includes(Number(dealId))));
        return product.variants.length > 0;
      }else if(filter === 'lineage'){
        return activeFilters[filter].includes(product?.flower_type?.replace(/_/g, ' '));
      }else if(filter === 'sdp_category'){
        return activeFilters[filter].includes(product?.sdp_category);
      }else if(filter==='search' && activeFilters[filter][0] && activeFilters[filter][0].length > 2){
        return searchProduct(product_data, activeFilters[filter][0].toLowerCase(), product);
      }else if(filter === 'doh_compliant'){
        product.variants = product.variants.filter(variant => variant.doh_compliant);
        return product.variants.length > 0;
      }else {return true;}
    })) {
      filtered_products.push(product);
    }
  });
  return filtered_products
}

const searchProduct = (product_data, searchTerm, product) => {
  const productName = (product.name || "")?.toLowerCase();
  const productDescription = (product.description || "")?.toLowerCase();
  const concentrateType = (product.concentrate_type || "")?.toLowerCase();

  const brand = product_data.brands?.find(
    (brand) => brand.id === product.brand_id
  );
  const brandName = (brand ? brand.name : "").toLowerCase();

  const strain = product_data.strains?.find(
    (strain) => strain.id === product.strain_id
  );
  const strainName = (strain ? strain.name : "").toLowerCase();

  const terpenes =
    product.terpene_ids?.filter(id => notNull(id))?.map((terpeneId) =>
      product_data.terpenes
        ?.find((terpene) => terpene.id === terpeneId)
        ?.name?.toLowerCase()
    ) || [];

  const effects =
    product.effect_ids?.filter(id => notNull(id))?.map((effectId) =>
      product_data.effects
        ?.find((effect) => effect.id === effectId)
        ?.name?.toLowerCase()
    ) || [];

  const flavors =
    product.flavor_ids?.filter(id => notNull(id))?.map((flavorId) =>
      product_data.flavors
        ?.find((flavor) => flavor.id === flavorId)
        ?.name?.toLowerCase()
    ) || [];
  const tags = product.tag_list?.map((tag) => tag.toLowerCase()) || [];
  // if(terpenes.length > 0 || effects.length > 0 || flavors.length > 0) {debugger}
  const includesSearchTerm =
    productName.includes(searchTerm) ||
    productDescription.includes(searchTerm) ||
    brandName.includes(searchTerm) ||
    strainName.includes(searchTerm) ||
    tags.includes(searchTerm) ||
    concentrateType.includes(searchTerm) ||
    terpenes.some((terpene) => terpene.includes(searchTerm)) ||
    effects.some((effect) => effect.includes(searchTerm)) ||
    flavors.some((flavor) => flavor.includes(searchTerm));

  return includesSearchTerm;
};

export const getWeightTypeIds = (product_types) => {
  return product_types?.filter(type => type.product_family_name === 'Flower' || type.product_family_name === 'Concentrate' || type.product_family_name === 'Preroll').map(type => type.id);
}


export const filterTime = (arr, select_ids = null) => {
  let applicable_arr = arr
  if(notNull(select_ids)){
    applicable_arr = arr.filter(item => select_ids.includes(item.id))
    applicable_arr.sort((a, b) => select_ids.indexOf(a.id) - select_ids.indexOf(b.id))

  }
  if(!notNull(applicable_arr) || applicable_arr.length === 0) {return []};
  const response = applicable_arr.filter(item => {
    let timeWindow = item
    if(notNull(item) && 'start_at' in item){
      timeWindow.hour_start = item.start_at
      timeWindow.hour_end = item.end_at
    }
    if(notNull(item) && item.hasOwnProperty('safe_name')){
      timeWindow = item.window_of_time
    }
    if (!notNull(timeWindow) || (Array.isArray(timeWindow) && timeWindow.length === 0)) {
      return true;
    }else if (Array.isArray(timeWindow) && timeWindow.length > 0) {
      timeWindow = timeWindow[0];
    }
    function convertTo24Hour(timeStr) {
      if(timeStr === '') return false;
      const [time, modifier] = timeStr.split(' ');
      let [hours, minutes] = time.split(':');
      if (hours === '12') {
        hours = '00';
      }
      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }
      return parseFloat(hours) + parseFloat(minutes) / 60;
    }

    const startDate = timeWindow.start_date || '';
    const endDate = timeWindow.end_date || '';
    const weekdays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const days = timeWindow.days;
    const hours_start = convertTo24Hour(timeWindow.hour_start)
    const hours_end = convertTo24Hour(timeWindow.hour_end)
    const now = new Date();
    const day = now.getDay();
    const hour = now.getHours();
    const minutes = now.getMinutes();
    const current_time = hour + minutes/60;
    let date_match = true
    if(startDate.length > 0 && endDate.length > 0){
      const start = new Date(startDate);
      const end = new Date(endDate);
      date_match = now >= start && now <= end;
    }else if(endDate.length > 0){
      const end = new Date(endDate);
      date_match = now <= end;
    }
    const day_match = days.includes(weekdays[day]) || (days.length === 1 && days[0] === '');
    const time_match = [hours_start, hours_end].includes(false) || (current_time >= hours_start && current_time <= hours_end)
    return day_match && time_match && date_match;
  })
  return response;
}
