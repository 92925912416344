import React from 'react'
import Shortcut from './Shortcut';
import { Flex } from 'antd';


export default function Shortcuts({key, component}) {
  const {
      display_icons,
      items
    } = component;

  return (
    <Flex justify='space-evenly' wrap style={{padding:20}}>
      {items.map((item) => {
        return (
          <Shortcut display_icons={display_icons} item={item}/>
        )
      })}
    </Flex>
  )
}
