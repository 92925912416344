import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchQuoteCartInfoApi = createAsyncThunk(
  "initializeQuotes/fetch",
  async (reference_no, payload) => {
    try {
      const response = await fetch(`${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${sessionStorage.getItem("selectedVenue")}/v1/quotes/${reference_no}`, {
        headers: {
          merchantToken: sessionStorage.getItem("merchantToken"),
          contentType: "application/json",
        //   authorization: 'Bearer 1vm1ax-tIxpocYteXD1FWA',
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch get Cart info info");
      }

      const data = await response.json();

      return data;
    } catch (error) {

      throw new Error(error.message);
    }
  }
);
