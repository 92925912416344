import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { create } from 'xmlbuilder2';
import { notNull } from '../sharedFunctions';
import { Spin } from 'antd';
import slugify from 'react-slugify';

const Sitemap = ({ }) => {
  const [sitemap, setSitemap] = useState('');
  const [ready, setReady] = useState(false);
  const menuFeed = useSelector((state) => state.menuFeed?.info?.product_data);

  useEffect(() => {
    if(notNull(menuFeed)){
      setReady(true);
      const paramsObject = paramBuilder();
      const xml = generateSitemap(paramsObject);
      setSitemap(xml);
      // createDownloadLink(xml);
    }
  }, [menuFeed]);

  const paramBuilder = () => {
    const paramsObject = menuFeed?.brands?.map((brand) => ({ brands: brand.name, product_id: [] }));
    menuFeed?.menu_items.forEach((product) => {
      const brand = menuFeed?.brands.find((brand) => brand.id === product.brand_id);
      if(notNull(brand)){
        return paramsObject.find(obj => obj.brands === brand.name).product_id.push(slugify(product.name));
      }
    });
    menuFeed?.categories?.forEach((category) => {
      paramsObject.push(({ category: category.safe_name }));
    });
    return paramsObject;
  };

  const generateSitemap = (paramsObject) => {
    const urlset = create({ version: '1.0', encoding: 'UTF-8' }).ele('urlset', { xmlns: 'http://www.sitemaps.org/schemas/sitemap/0.9' });
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
    urlset.ele('url')
      .ele('loc').txt(`${window.posabitmenuwidget.config.ecomm_url}`).up()
      .ele('lastmod').txt(currentDate).up() // Dynamic date for lastmod
    paramsObject.forEach(param => {
      if ('category' in param) {
        const path = `/products?category=${param.category}`;
        urlset.ele('url')
          .ele('loc').txt(`${window.posabitmenuwidget.config.ecomm_url}${path}`).up()
          .ele('lastmod').txt(currentDate).up() // Dynamic date for lastmod
          .up();
      }else if ('brands' in param){
        param.product_id.forEach(productId => {
          const path = `/${slugify(param.brands)}/${productId}`;
          urlset.ele('url')
          .ele('loc').txt(`${window.posabitmenuwidget.config.ecomm_url}${path}`).up()
          .ele('lastmod').txt(currentDate).up() // Dynamic date for lastmod
          .up();
        });
      }
    });
    return urlset.end({ prettyPrint: true });
  };
  
  return (
    <div>
      {
        ready ?
          <pre>{sitemap}</pre>
        :
        <Spin spinning={!ready} size="large" fullscreen style={{zIndex: 1400}}/>

      }
    </div>
  );
};

export default Sitemap;
