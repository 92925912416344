

// Create getter and setter to store reference_no in local storage keyed off a venue_id.
// Store the time the reference_no was set and reset the reference_no to null if it's over
// 1 day old.

export const getReferenceNo = (venueId) => {
  const key = "ref"+venueId;
  const venueRef = JSON.parse(localStorage.getItem(key));
  if (venueRef && venueRef.time) {
    const currentTime = new Date().getTime();
    if (currentTime - venueRef.time > 86400000) {
      localStorage.removeItem(key);
      return null;
    }
    // update the time on the reference_no
    setReferenceNo(venueId, venueRef.reference_no);
    return venueRef.reference_no;
  }
  return null;
}

export const setReferenceNo = (venueId, reference_no) => {
  const key = "ref"+venueId;
  const currentTime = new Date().getTime();
  if ( reference_no ) {
    localStorage.setItem(key, JSON.stringify({ reference_no: reference_no, time: currentTime }));
  } else {
    localStorage.removeItem(key);
  }
}
