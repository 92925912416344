import { createSlice } from "@reduxjs/toolkit";
import { fetchMerchantInfo } from "../actions";

const merchantSlice = createSlice({
  name: "merchant",
  initialState: {
    info: null,
    loading: false,
    error: null,
  },
  reducers: {
    changeVenue:(state,action)=>{
      state.info = {...state.info,merchant:{...state.info?.merchant,venues:state.info?.merchant?.venues.map((vn)=>{
        if(vn.slug === action.payload){
          return {...vn,selected:true}
        }else{
          return {...vn,selected:false}
        }
      })}}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMerchantInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
        state.error = null;
      })
      .addCase(fetchMerchantInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {changeVenue} = merchantSlice.actions

export default merchantSlice.reducer;
