import React from "react";
import { Result, Button } from 'antd'
import { useNavigate } from "react-router";

export default function ErrorPage() {
  const navigate = useNavigate();

  return (
    <div>
      <Result
      status='error'
      title='Something Went Wrong'
      subTitle='Please try again later'
      extra={<Button type='primary' onClick={() => navigate('/')}>Back Home</Button>}
      />
    </div>
  );
}
