export const capCase = (str) =>  str.replace(/\b\w/g, (l) => l.toUpperCase());

export const notNull = (value) => value !== null && value !== undefined;

export const notEmpty = (value) => value !== null && value !== undefined && value !== '';

export const  arraySubtractAll = (arr1, arr2) => {
  let result = [...arr1];
  for (let item of arr2) {
      let index;
      while ((index = result.indexOf(item)) !== -1) {
          result.splice(index, 1);
      }
  }
  return result;
}
