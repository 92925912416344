import { createAsyncThunk } from "@reduxjs/toolkit";
import { setReferenceNo } from "../utility/orderUtils";

export const submitOrderData = createAsyncThunk(
  "submitOrder",
  async (payload) => {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("MerchantToken", sessionStorage.getItem("merchantToken"));

      let raw = JSON.stringify({payment:payload?.payment});

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${payload.slug}/v1/quotes/${payload?.reference_no}/finalize`,
        requestOptions
      );
      if (response.ok) {
        const data = await response.json();
        data.success = true;
        setReferenceNo(data?.quote?.venue_id, null);
        return data;
      }else{
        const error = await response.json();
        return {message: error?.errors, status: response.status, success: false};
        // throw new Error("Failed to fetch submit order");
      }
    } catch (error) {
      return {message: error.message, success: false};
      // throw new Error(error.message);
    }
  }
);


