import { Typography } from 'antd'
import React from 'react'


const WeightRange = ({product}) => {

  const displayRange = () => {
    let response
    if (product?.variants?.length > 3) {
      const weight = product?.variants?.map((variant) => variant.unit)
      const unit_type = product?.variants?.[0]?.unit_type
      const min = Math.min(...weight)
      const max = Math.max(...weight)
      response = `${min}${unit_type} - ${max}${unit_type}`
    }else if (product?.variants?.length > 1) {
      response = product?.variants?.map((variant) => `${variant.unit}${variant.unit_type}`).join(', ')
    }else{
      response = (product?.variants?.[0]?.unit && product?.variants?.[0]?.unit_type) ? 
        `${product?.variants?.[0]?.unit}${product?.variants?.[0]?.unit_type}` :
        ''
    }
    if(response === '0.0g'){
      response = ''
    }
    return response
  }
  return (
    <Typography.Text type='secondary'>
      {displayRange()}
    </Typography.Text>
  )
}

export default WeightRange
