import slugify from 'react-slugify';

export const fetchBrandId = (brandName, menuFeedData) => {
    let brandId;

    if ( brandName === 'brand' ) return null;

    const filteredBrandArr = menuFeedData?.product_data?.brands.filter((brand) => {
        return brandName === slugify(brand?.name)
    });

    if (filteredBrandArr?.length >= 0) {
        brandId = filteredBrandArr[0]?.id;
    }
    return brandId;
}

export const fetchProduct = (productName, menuFeedData) => {
    let foundProduct;

    const filteredProductArr = menuFeedData?.product_data?.menu_items.filter(  (product) => {

        return productName === slugify(product?.url_slug);
    });

    if (filteredProductArr?.length >= 0) {
        foundProduct = filteredProductArr[0];
    }
    return foundProduct;
}

export const productDetailUrl = (product) => {
    return(
        product?.brand_id
            ? `/${slugify(product?.brand)}/${slugify(
            product?.url_slug,
            )}`
            : `/brand/${slugify(product?.url_slug)}`
    );
}

// Parse filters from the URL and return a filters object
export const parseFiltersfromURL = (filterKey = []) => {
    const queryParams = new URLSearchParams(window.location.search);
    return parseFiltersFromQueryParams(queryParams, filterKey);
}

export const parseFiltersFromQueryParams = (queryParams, filterKey = []) => {
    const filters = {};
    filterKey.push('search', 'subcategory');
    queryParams.forEach((value, key) => {
        if (key !== "server_host" && filterKey.includes(key)) {
            filters[key] = value.split(",");
        }
    });
    return filters;
}
