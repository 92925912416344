import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchMerchantInfo = createAsyncThunk(
  "merchant/fetchInfo",
  async (merchantToken) => {
    try {
      const response = await fetch(`${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/merchant/v1/info`, {
        headers: {
          merchantToken: sessionStorage.getItem("merchantToken"),
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch merchant info");
      }

      const data = await response.json();
      return {...data,merchant:{...data?.merchant,venues:data?.merchant?.venues.map((vn,index)=>{
        if(index === 0){
          return {...vn,selected:true}
        }else{
          return {...vn,selected:false}
        }
      })}};
    } catch (error) {
      throw new Error(error.message);
    }
  }
);
