import { createSlice } from "@reduxjs/toolkit";
 import { submitOrderData } from "../SubmitOrderAction";

const submitOrder = createSlice({
  name: "submitOrder",
  initialState: {
    info: null,
    loading: false,
    error: null,
  },
  reducers: {
    emptySubmitOrder:(state, action) => {
      state.info = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitOrderData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitOrderData.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(submitOrderData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const {emptySubmitOrder} = submitOrder.actions
export default submitOrder.reducer;

