import { createAsyncThunk } from "@reduxjs/toolkit";

export const getDiscountData = createAsyncThunk("discount", async (payload) => {
    const selectedVenue = payload || sessionStorage.getItem("selectedVenue");

try {
  const discountUrl = `${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${selectedVenue}/v1/coupons`;

  const response = await fetch(discountUrl, {
      headers: {
        merchantToken: sessionStorage.getItem("merchantToken"),
        authorization: "Bearer 1vm1ax-tIxpocYteXD1FWA",
      },
    });

    if (!response.ok) {
      throw new Error("No data found");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  
}
});
