import { createAsyncThunk } from "@reduxjs/toolkit";
import { setReferenceNo } from '../utility/orderUtils';

const createHeaders = () => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("MerchantToken", sessionStorage.getItem("merchantToken"));
  return headers;
};

export const postInitializeQuotesApi = createAsyncThunk("initializeQuotes/post", async ( payload ) => {
  
  try {
    let myHeaders = createHeaders();
    let raw = JSON.stringify(payload);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${payload.slug}/v1/quotes`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }

    const data = await response.json();
      setReferenceNo(data?.quote?.venue_id, data?.quote?.reference_no);
      return data;
  } catch (error) {
    throw new Error(error.message);
  }
});

export const putQuotesUpdateItemsApi = createAsyncThunk("initializeQuotes/put", async (payload) => {
 
  try {
    let myHeaders = createHeaders();
    let raw = JSON.stringify(payload?.quote || {});
    let tail = 'items';
    if(payload?.quote?.customer || payload?.quote?.payment || payload?.quote?.fulfillment){tail = ''}

    const apiUrl = `${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${payload.slug}/v1/quotes/${payload?.reference_no}/${tail}`;

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(apiUrl, requestOptions);

    if (!response.ok) {
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
});

