import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectVenueInfo, selectVenueLoading, selectVenueError } from "../store/slice/venueSlice";
import * as Constant from "../Constants";
import { Space } from "antd";

const StoreOpenTime = () => {
  const header = useSelector((state) => state.content?.contentData?.components.filter((c) => c.type === "heading")[0]);
  const venueInfo = useSelector(selectVenueInfo);
  const isLoading = useSelector(selectVenueLoading);
  const error = useSelector(selectVenueError);
  const [currentDayInfo, setCurrentDayInfo] = useState(null);

  useEffect(() => {
    if (venueInfo && venueInfo.venue && venueInfo.venue.store_hours) {
      const today = new Date().toLocaleDateString("en-US", { weekday: "long" });
      const todayHours = venueInfo?.venue?.store_hours?.find(day => day.day?.toLowerCase() === today?.toLowerCase());
      setCurrentDayInfo(todayHours);
    }
  }, [venueInfo]);

  if (isLoading) {
    return <div className="loading">{Constant.LOADING}</div>;
  }

  if (error) {
    return <div>Something went wrong. {error.message}</div>;
  }

  const isStoreClosed = venueInfo && venueInfo.venue && currentDayInfo?.is_closed;

  return (
    <div className="">
      <Space>

        <i className="fa fa-clock"
          style={{color: `${header?.text_color}` || "#000",
          fontSize: 14,
        }}
        />
        {isStoreClosed ? (
          <span>Store Closed</span>
        ) : (
          currentDayInfo ? (
            <span style={{color: `${header?.text_color}`, fontSize: 14}}>{currentDayInfo?.open_time} - {currentDayInfo?.close_time}</span>
          ) : (
            <span>Store hours not available for today</span>
          )
        )}
      </Space>
    </div>
  );
};

export default StoreOpenTime;
