import React from "react";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchContentData = createAsyncThunk(
  "content/fetchContentData",
  async (payload, thunkAPI) => {
    const selectedVenue = payload || sessionStorage.getItem("selectedVenue");

    try {
      const response = await fetch(`${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${selectedVenue}/v1/menu_feeds/${sessionStorage.getItem("feedId")}/content`, {
        headers: {
          merchantToken: sessionStorage.getItem("merchantToken"),
          authorization: "Bearer 1vm1ax-tIxpocYteXD1FWA",
        },
      });

      if (!response.ok) {
        throw new Error("No data found");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);
