import { combineReducers } from '@reduxjs/toolkit';
import merchantReducer from './slice/merchantSlice';
import MenuFeedAPISlice from './slice/MenuFeedAPISlice';
import venueReducer from "./slice/venueSlice";
import SubmitOrderSlice from "./slice/SubmitOrderSlice";
import ContentSlice from "./slice/ContentSlice";
import SdpData from "./slice/sdpSlice";
import DiscountData from "./slice/discountSlice";
import quotesSlice from './slice/quoteSlice';

const rootReducer = combineReducers({
  merchant: merchantReducer,
  menuFeed:MenuFeedAPISlice,
  quote: quotesSlice,
  venue: venueReducer,
  submitOrderRecord: SubmitOrderSlice,
  content: ContentSlice,
  sdpData:SdpData,
  discountData:DiscountData,
  
});

export default rootReducer;
