import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { primaryColor } from '../store/slice/ContentSlice.js';
import { emptyQuote } from "../store/slice/quoteSlice.js";

const OrderThankyouPage = () => {
  const primaryColorValue = useSelector(primaryColor);
  useEffect(() => {
    document.documentElement.style.setProperty('--dynamic-color', primaryColorValue);
  }, [primaryColorValue]);
  const [isFetching, setIsFetching] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const navigateHome = () => {
    // dispatch(emptyInitializeQuotes())
    //  dispatch(emptyUserinfoSlice())
    // dispatch(emptySubmitOrder())

    // 👇️ navigate to /
    navigate("/");
  };

  const incomingOrderId = useSelector(
    (store) => store?.submitOrderRecord?.info?.quote?.incoming_order_id
  );

  useEffect(() => {
    setTimeout(function () {
      setIsFetching(false);
    }, 900);
    dispatch(emptyQuote())
  }, []);

  return (
    <>
      <div className="thank-you-page">
        <div className="img-section">
          <img
            className="order"
            src={window.location.origin + "/images/orderplaced.png"}
            alt="orderplaced"
          ></img>
        </div>
        <h1>Thank you for your order!</h1>
        <h5 className="order-placed">
          Your order has been successfully placed.
        </h5>
        <div className="order-id">Your Order Id : {isFetching ? "Loading.." : incomingOrderId}</div>
        <div className="order-details"></div>
        <div className="empty_cart text-center container">
          <button className="btn btn-info mt-4 w-25" onClick={navigateHome}>
            Continue Shopping
          </button>
        </div>
      </div>
    </>
  );
};

export default OrderThankyouPage;
