import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Constant from "../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { submitOrderData } from "../store/SubmitOrderAction";
import { emptyUserinfoSlice } from "../store/slice/UserInfoSlice";
import { emptyQuote } from "../store/slice/quoteSlice.js";
import { Input, Popconfirm, Space, message, Button, Flex, Typography, Card } from "antd";
import { notEmpty, notNull } from "../sharedFunctions.js";
import { getReferenceNo } from '../utility/orderUtils';

const { Paragraph } = Typography;

const ProcessToCheckOut = ({ formStep }) => {
  const [code, setCode] = useState(null);
  const location = useLocation()
  const quote = useSelector((store) => store?.quote?.info?.quote);
  const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
  const reference_no = getReferenceNo(venue_id);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [pin, setPin] = useState('')
  const [ready, setReady] = useState(false)
  const otpRef = useRef(null);
  const priceIncludedTax = quote?.taxes.every((tax) => tax.price_included);

  const calculateTotalPrice = (totals) => {
    if (totals && totals.total) {
      return totals.total / 100;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const codeFromUrl = searchParams.get('code');

    if (codeFromUrl) {
      setCode(codeFromUrl);
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, []);

  useEffect(() => {
    const accountLinked = () => {
      if(quote?.payment?.requested_payment_method === 'posabit_pay'){
        return notNull(code)
      }else { return true }
    }
    const valid = quote?.items?.length >= 1 &&
    notEmpty(quote?.customer?.first_name) &&
    notEmpty(quote?.customer?.last_name) &&
    notEmpty(quote?.customer?.email) &&
    notEmpty(quote?.customer?.telephone) &&
    notNull(quote?.payment?.requested_payment_method) &&
    accountLinked() &&
    quote?.limits?.exceeded_limits.length === 0;
    setReady(valid)
  }, [quote])


  // Inside your component
  const totals = useSelector((store) => store?.quote?.info?.quote?.totals);
  const totalPrice = calculateTotalPrice(totals);

  const navigate = useNavigate();


  const dispatch = useDispatch();

  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlug = slugId;

  const submitOrder = () => {
    const slugId = sessionStorage.getItem("selectedVenue") || defaultSlug;
    let data = {}
    if (isPosaBitPay()) {
      if(!pin){
        message.error({
          content:'No Pin',
          icon: <i className='fa fa-exclamation-circle' style={{color: 'red'}}/>
        });
        return;

      } else if (pin.length !== 4){
        message.error({
          content:'Pin must be 4 digits',
          icon: <i className='fa fa-exclamation-circle' style={{color: 'red'}}/>
        });
      } else if ( /\D/.test(pin)){
        message.error({
          content:'Pin must be a number',
          icon: <i className='fa fa-exclamation-circle' style={{color: 'red'}}/>
        });
        return;
      } else if(!code) {
        message.error({
          content:'Account not Linked',
          icon: <i className='fa fa-exclamation-circle' style={{color: 'red'}}/>
        });
        return;
      }
      data = {
        pac: pin,
        code: code,
        redirect_uri: window.posabitmenuwidget.config.ecomm_url + '/checkout'
      }
    }
    setConfirmLoading(true);
    const submitOrder = async () => {
      const response = await dispatch(
        submitOrderData({
          slug: slugId,
          reference_no,
          payment: data,
        })
      );
      return response;
    }

    if (reference_no) {
      submitOrder()
      .then((response) => {
        if(response.payload.success){
          setConfirmLoading(false);
          dispatch(emptyUserinfoSlice())
          // dispatch(emptySubmitOrder())

          dispatch(emptyQuote())
          navigate("/thank-you");
        }else{
          setConfirmLoading(false);
          message.error({
            content: notEmpty(response.payload.message) ? response.payload.message : 'Your payment method failed, please verify your account and try again',
            icon: <i className='fa fa-exclamation-circle' style={{color: 'red'}}/>
          });
        }
      })
      .catch((error) => {
        message.error({
          content: 'Failed to fetch submit order',
          icon: <i className='fa fa-exclamation-circle' style={{color: 'red'}}/>
        });
      });
      }
    }

  const confirmPrice = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("MerchantToken", sessionStorage.getItem("merchantToken"));
    return headers;
  }

  const isPosaBitPay = () =>  quote?.payment?.requested_payment_method === 'posabit_pay';
  const tipCalculator = () => {
    let tipFinal = quote?.fulfillment?.tip_calculated
    if (notNull(tipFinal)) {
      return parseFloat(tipFinal) / 100;
    }else{
      return 0
    }
  }

  const totalCalculated = () => {
    let tip = 0
    let fee = 0
    if (quote?.payment?.requested_payment_method === 'posabit_pay'){
      tip = quote?.fulfillment?.tip_calculated || 0
      fee = quote?.fulfillment?.processing_fee || 0
    }
    return (totals.total + tip + fee) / 100;
  }

  return (
    <Card
      title={Constant.CART_TOTALS}
      style={{ borderColor: '#d9d9d9' }}
    >
      <Flex justify="space-between">
        <Paragraph>{Constant.SUBTOTAL}</Paragraph>
        <Paragraph>${(totals?.subtotal / 100).toFixed(2)}</Paragraph>
      </Flex>
      {
        totals?.discount > 0 &&
        <>
        <Flex justify="space-between">
          <Paragraph type='success'>{Constant.DISCOUNT}</Paragraph>
          <Paragraph type='success'>{Constant.MINUS_DOLLAR}{(totals?.discount / 100).toFixed(2)}</Paragraph>
        </Flex>
        </>
      }
      <Flex justify="space-between">
        <Paragraph>{priceIncludedTax ? Constant.TAX_INCLUDED_IN_PRICE : Constant.TAX}</Paragraph>
        <Paragraph>{Constant.DOLLAR}{(totals?.tax / 100).toFixed(2)}</Paragraph>
      </Flex>
      {
        isPosaBitPay() &&
        <>
          <Flex justify="space-between">
            <Paragraph>Tip:</Paragraph>
            <Paragraph>{Constant.DOLLAR}{tipCalculator().toFixed(2)}</Paragraph>
          </Flex>
          <Flex justify="space-between">
            <Paragraph>Fee:</Paragraph>
            <Paragraph>{Constant.DOLLAR}{(parseFloat(quote?.fulfillment?.processing_fee) / 100).toFixed(2)}</Paragraph>
          </Flex>
        </>
      }
      <Flex justify="space-between">
        <Paragraph strong>{Constant.TOTAL}</Paragraph>
        <Paragraph strong>{Constant.DOLLAR}{totalCalculated().toFixed(2)}</Paragraph>
      </Flex>
      <Flex align="center" justify="right">
        {
          isPosaBitPay() ?
          <Popconfirm
            title="Confirm Price"
            onPopupClick={() => otpRef.current.focus()}
            okButtonProps={{ loading: confirmLoading }}
            icon={<i className='fa fa-exclamation-circle' style={{color: 'gold'}}/>}
            description={
              <div>
                <Space direction='vertical'>

              <Typography.Text type='secondary'>
                POSaBit Pay PIN
              </Typography.Text>
              <Input.OTP
                ref={otpRef}
                autoFocus
                size={'large'}
                length={4}
                // visibilityToggle={true}
                value={pin}
                mask='•'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    submitOrder()
                  }
                }}
                onChange={(e) => {
                  setPin(e)
                }}
                />
                </Space>
              </div>
            }
            onConfirm={submitOrder}
          >

            <Button
              type="primary"
              // className="btn btn-info Checkoutbtn"
              disabled={!ready}
              onClick={() => {
                confirmPrice()
              }}
              loading={confirmLoading}
              size='large'
              style={{
                padding: 30,
                paddingBottom: 30,
                width: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
              }}
            >
              {Constant.PROCEED_TO_CHECKOUT}
            </Button>
          </Popconfirm>
          :
          <Button
            type="primary"
            size='large'
            style={{
              padding: 30,
              paddingBottom: 30,
              width: 200,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
            }}
            disabled={!ready}
            loading={confirmLoading}
            onClick={() => {
              submitOrder()
            }}
          >
            {Constant.PROCEED_TO_CHECKOUT}
          </Button>
        }
      </Flex>
    </Card>
  );
};
export default ProcessToCheckOut;
