import React, { useEffect, useState } from "react";
import { Select, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { fetchMerchantInfo } from "../store/actions";
import { fetchMenuFeedApi } from "../store/MenuFeedaction";
import { fetchVenueInfo } from "../store/slice/venueSlice";
import { changeVenue } from '../store/slice/merchantSlice';
import { fetchContentData } from "../store/ContentAction";
import { getSdpData } from "../store/SdpAction.js";
import { getDiscountData } from "../store/discountAction.js";
import { useLocation } from 'react-router-dom';
import * as Constant from "../Constants";
import { headerTextColor } from '../store/slice/ContentSlice.js';
import { emptyQuote } from "../store/slice/quoteSlice.js";
import { notNull } from "../sharedFunctions";

const StoreLocation = ({ onVenueChange }) => {
  const header = useSelector((state) => state.content?.contentData?.components.filter((c) => c.type === "heading")[0]);
  const content = useSelector((state) => state.content?.contentData);
  const venue = useSelector((state) => state.venue?.info?.venue);
  const merchantInfo = useSelector((state) => state.merchant?.info?.merchant);
  const headerTextColorValue = useSelector(headerTextColor);
  useEffect(() => {
    document.documentElement.style.setProperty('--headertext-color', headerTextColorValue);
  }, [headerTextColorValue]);
  const dispatch = useDispatch();
  const { info, loading, error } = useSelector((state) => state.merchant || {});
  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );

  const [selectedVenue, setSelectedVenue] = useState(
    sessionStorage.getItem("selectedVenue") || slugId || ""
  );
  const location = useLocation();

  useEffect(() => {
    if (notNull(venue)) {
      const head = document.head;
      const metaElements = [
        { itemProp: 'address', content: venue?.address },
      ]
      metaElements.forEach((metaElement) => {
        let existingMeta = document.querySelector(`meta[itemProp="${metaElement.itemProp}"]`);
        if (existingMeta) {
          existingMeta.setAttribute('content', metaElement.content);
        } else {
          const element = document.createElement('meta');
          Object.keys(metaElement).forEach((key) => {
            element.setAttribute(key, metaElement[key]);
          });
          head.appendChild(element);
        }
      });
    }
  },[venue])
  // Fetch merchant info on component mount
  // useEffect(() => {
  //   dispatch(fetchMerchantInfo(sessionStorage.getItem("merchantToken")));
  // }, [dispatch, content]);
  // Set sessionStorage before handling venue change
  useEffect(() => {
    sessionStorage.setItem("selectedVenue", selectedVenue ? selectedVenue : slugId);
  }, [selectedVenue, slugId]);

  const changeVenue = (venue) => {
    sessionStorage.setItem("selectedVenue", venue);
    const selectedVenue = merchantInfo?.venues?.find((v) => v.slug === venue);
    window.location.href = selectedVenue?.ecomm_url;
  };

  const isHomepage = location.pathname === "/";

  if (loading) {
    return <div className="loading">{Constant.LOADING}</div>;
  }

  if (error) {
    return <div>{Constant.ERROR} {error}</div>;
  }

  const availableVenues = () => info?.merchant?.venues?.filter(venue => venue.menus.length > 0);

  return (
    <>
      {(isHomepage && availableVenues().length > 1 && content.menu.mode === 'online') ?
        <>
          <Space>
            <i className="fa fa-location" style={{color: `${header?.text_color}` || "#000"}}/>
            <Select className=""
              onChange={changeVenue} value={selectedVenue || ""}
              variant='borderless'
              options={availableVenues().map((venue) => ({value: venue?.slug, label: venue?.menus?.filter(ven => ven?.ecomm_url?.length > 0 && ven?.mode === 'online')?.[0]?.store_name || venue?.name}))}
              suffixIcon={<i className="fa fa-angle-down" style={{color: `${header?.text_color}` || "#000"}}/>}
              dropdownStyle={{
                color: `${header?.text_color}` || "#000",
                backgroundColor: `${header?.heading_color}` || "#fff",
                width: 300,
              }}
              />
          </Space>
        </>
        :
        <Space>
          <i className="fa fa-location"/>
           <span className=" "
            style={{color: `${header?.text_color}` || "#000"}}
           > {info?.merchant?.venues?.find(venue => venue?.slug === selectedVenue)?.menus?.[0]?.store_name || info?.merchant?.venues?.find(venue => venue?.slug === selectedVenue)?.name}</span>
        </Space>
      }
    </>
  );
};

export default StoreLocation;
