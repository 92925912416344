import { Tag } from "antd";
import React from "react";
import * as Constant from '../Constants';
import { capCase } from "../sharedFunctions";


const LineageTag = ({ lineage }) => {


  return (
    <Tag
      color={Constant.LINEAGE_DATA[lineage].color}
      style={{borderRadius: '10px'}}
    >
      {Constant.LINEAGE_DATA[lineage].display_name  || capCase(lineage)}
    </Tag>
  )
}

export default LineageTag;
