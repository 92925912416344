export const FIRSTNAME_ERROR = "First Name is Required";
export const LASTNAME_ERROR = "Last Name is Required";
export const EMAIL_ERROR = "Email is Required";
export const EMAIL_ERRORONE = "Please Input Valid Email Id";
export const PHONE_ERROR = "Phone is Required";
export const PHONE_ERRORONE = "Phone No. Should be in Integer";
export const ADDRESS_ERROR = "Address is Required";
export const ZIPCODE_ERROR = "Zipcode is Required";
export const ZIPCODE_ERRORONE = "Please Enter Valid Zipcode";
export const CITY_ERROR = "City is Required";
export const STATE_ERROR = "State is Required";
export const BIRTHDAY_ERROR = "Birthday is Required";
export const GENDER_ERROR = "Gender is Required";
export const PASSWORD_ERROR = "Password is Required";
export const PASSWORD_ERROR_LENGTH =
  "Password should be greater than 6 characters.";
export const SIGNUP_SUCCESS_MESSAGE = "You Have Registered Successfully";
export const DETAILS = "Details";
export const YOU_MAY_ALSO_LIKE = "You May Also Like";
export const SHOPPING_CART = "Shopping cart";
export const START_SHOPPING = "SHOP PRODUCTS";
export const SUBTOTAL = "Sub Total:";
export const DISCOUNT = "Discount:";
export const TAX = "Tax:";
export const TAX_INCLUDED_IN_PRICE = "Tax (included in price):";
export const TOTAL = "TOTAL:";
export const VIEW_CART = "VIEW CART";
export const CHECK_OUT = "Checkout";
export const DOLLAR = "$";
export const MINUS_DOLLAR = "-$";
export const LEGAL_MARIJUANA_SHOP = "Legal Marijuana Online shop";
export const MEDICAL_CANNABIS = "20% off on medical cannabis";
export const SED_VIVERRA =
  "Sed viverra, lorem in maximus faucibus, odio libero fringilla dolor, convallis vestibulum risus nisi ac neque. Maecenasconvallis ligula.";
export const SHOP_NOW = "Shop now";
export const PREVIOUS = "Previous";
export const NEXT = "Next";
export const BRAND = "Brand";
export const LOADING = "Loading...";
export const ERROR = "Error:";
export const VIEW_ALL = "View all Products";
export const NO_PRODUCTS_FOUND = "No products found";
export const CATEGORY = "Category";
export const SORT_BY = "Sort By";
export const A_Z = "A-Z";
export const Z_A = "Z-A";
export const PRICE_LOW_TO_HIGH = "Price Low to High";
export const PRICE_HIGH_TO_LOW = "Price High to Low";
export const THC_LOW_TO_HIGH = "THC% Low to High";
export const THC_HIGH_TO_LOW = "THC% High to Low";
export const CBD_LOW_TO_HIGH = "CBD% Low to High";
export const CBD_HIGH_TO_LOW = "CBD% High to Low";
export const NO_PRODUCTS_AVAILABLE = "No Products Available.";
export const ADD_TO_CART = "Add to Cart";
export const FLOWER = "Flower (1g-14g)";
export const THC = "THC";
export const TOTAL_THC = "TOTAL THC"
export const CBD = "CBD";
export const WEIGHT = "Weight:";
export const REMOVE = "Remove";
export const MOVE_TO_CART = "Move to cart";
export const SELECT_BRAND = "Brand";
export const SELECT_TYPE = "Product Type";
export const SELECT_CATEGORY = "Category";
export const SELECT_STRAINS = "Strains";
export const SELECT_TERPENES = "Terpenes";
export const SELECT_FLAVORS = "Flavors";
export const SELECT_EFFECTS = "Effects";
export const APPLY_FILTER = "Apply Filters";
export const SETTING = "Setting";
export const ACCOUNT = "Account";
export const LOGOUT = "logout";
export const PRICE_RANGE = "Price Range";
export const MY_WISHLIST = "My Wishlist";
export const RETURN_TO_SHOPPING = "Return To Shopping";
export const YOUR_INFORMATION = "Your Information";
export const ENTER_CUSTOMER = "Enter Customer Information";
export const ENTER_PATIENT = "Enter Patient Information";
export const CONTINUE = "Continue";
export const LINK_ACCOUNT = "Link Account"
export const BACK = "Back";
export const NOW_ACCEPTING =
  "Now accepting debit card in-Store";
export const STORE_OPEN = "8am - 9pm";
export const REVIEW = "Review";
export const OUT_OF_STOCK = "Out of Stock";
export const FILTER_SECTION = "Filter Section";
export const HOME = "Home";
export const LEFT = "left";
export const QUANTITY = "Quantity";
export const HURRY_UP = "Hurry up! Only";
export const LOAD_MORE = "Load More";
export const CART_TOTALS = "Cart Totals";
export const APPLY = " Apply";
export const PROCEED_TO_CHECKOUT = "Place Order";
export const CONFIRM_PRICE = "Enter PIN to Confirm Price";
export const PRICE = "Price";
export const PICKUP_FREE = "Pickup Free";
export const DELIVERY = "Delivery";
export const PAYMENT = "Payment";
export const PAYMENT_METHOD = "Payment Method";
export const GOVERNMENT_ID = "Government ID";
export const THIS_WILL =
  "This will help stores validate your identity and will not be made visible to anyone else.";
export const CASH = "Cash";
export const DEBIT = "Debit (In store $ 3.50 fee)";
export const LINK =
  "I plan to pay at delivery with Debit/Credit ($3.50 FEE) and a link will be sent to process my payment";
export const REMOVE_ALL = "Remove All";
export const CONTINUE_SHOPPING = "Continue Shopping";
export const SELECT_POPULARITY = "Select Popularity";
export const MOST_POPULAR = "Most Popular";
export const LEAST_POPULAR = "Least Popular";
export const RECREATIONAL = "Recreational";
export const MEDICAL = "Medical";
export const THANKYOU = "Thank you. Your order has been placed.";
export const VENDOR_URL = "https://vendor.posabit.com";
// export const QA_URL = "https://qa-ecomm-app.posabit.com";
export const QA_URL = VENDOR_URL;
// export const MERCHANT_SLUG = "oregon_tops";
export const MERCHANT_SLUG = "hashtag-cannabis";
export const VENUE_SLUG = "seattle";
export const QUOTE_SLUG = "3313-1698772410-878fd07a";
export const FLOWER_IMAGE = "https://images.unsplash.com/photo-1589140915708-20ff586fe767?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
export const IMAGES = {
  flower: "https://images.unsplash.com/photo-1589140915708-20ff586fe767?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  edible_liquid: "https://images.unsplash.com/photo-1666402666560-7ab7f22b8360?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  edible_solid: "https://images.unsplash.com/photo-1666400958401-fe32c227398d?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  concentrate: "https://images.unsplash.com/photo-1597355117858-73bc4d7e8753?q=80&w=1734&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  pre_roll: "https://images.unsplash.com/photo-1649127472726-5396b1e85a31?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  infused_pre_roll: "https://images.unsplash.com/photo-1629724911400-84cd8ed86a1f?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  topical: "https://images.unsplash.com/photo-1573461160327-b450ce3d8e7f?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  vape_cartridge: "https://photos.google.com/share/AF1QipPqh8fe11Rx77msmqIDgp80X18y1ectwlU7zneDvAWaRjLVTdNqNuCaPA5XTB8Yog/photo/AF1QipPpwVm8F4hPbJCHBa8t0OdmLgCAqetaLff6Neti?key=VHFlblZnYVJOZndzU0ZUWDdSeGVBekR2X3F1ZTBn",
  paraphernalia: "https://images.unsplash.com/photo-1595174982183-79d62ba8e2ce?q=80&w=1754&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  sample: "https://images.unsplash.com/photo-1626106576790-b7dec4116c0a?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  tincture: "https://photos.google.com/share/AF1QipPqh8fe11Rx77msmqIDgp80X18y1ectwlU7zneDvAWaRjLVTdNqNuCaPA5XTB8Yog/photo/AF1QipMmxPti0O_4j5H5a6i1-4pOCZTseWHDr_FZxc5k?key=VHFlblZnYVJOZndzU0ZUWDdSeGVBekR2X3F1ZTBn",
}
export const BOX_SHADOW = "1px 20px 20px 0px rgb(255 249 249 / 0%), 1px -1px 8px 1px rgb(70 70 70 / 25%)"
export const TERPENE_COLOR = {
  indica: '#81259d',
  indica_hybrid: '#81259d',
  sativa: '#e55848',
  sativa_hybrid: '#e55848',
  hybrid: '#52ab39',
  cbd: '#f08b36',
}

export const LINEAGE_DATA = {
  indica: {display_name: 'Indica', color: 'purple'},
  indica_hybrid: {display_name: 'Indica Hybrid', color: 'purple'},
  sativa: {display_name: 'Sativa', color: 'red'},
  sativa_hybrid: {display_name: 'Sativa Hybrid', color: 'red'},
  hybrid: {display_name: 'Hybrid', color: 'green'},
  cbd: {display_name: 'CBD', color: 'orange'},
}
