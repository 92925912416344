import { Checkbox, Space, Switch, Typography } from 'antd';
import React, { useEffect, useState } from   'react';

const { Paragraph } = Typography;

const Subcategory = ({ updateFilters, child, filterCounts, parentFilter, activeFilters }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    // debugger
    if(!('subcategory' in activeFilters)) {
      return setChecked(false);
    }
    return setChecked(activeFilters?.subcategory?.includes(child.value));
  }, [activeFilters, child.value]);

  return (
    <Space>
    <Switch
      onChange={event => updateFilters('subcategory', {target: {value: child.value}})}
      // value={child.value.toString()}
      name={child.value.toString()}
      key={child.value}
      size='small'
      checked={checked}
    >
    </Switch>
      <Paragraph
        ellipsis={{rows: 2}}
        style={{display: 'block', margin: 0}}
      >
        {child.label} {filterCounts['subcategory']
        ? `(${filterCounts['subcategory'][child.value] || '0'})`
        : '(0)'
        }
      </Paragraph>
    </Space>
  );
}

export default Subcategory;
