import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import UserInformation from "./UserInformation";
import Payment from "./Payment";
import Review from "./Review";
import ProcessToCheckOut from "./ProcessToCheckOut";
import { Collapse, Typography, Space, Button, Avatar, Skeleton } from 'antd';
import * as Constant from "../Constants";
import { fetchQuoteCartInfoApi } from "../store/GetQuoteAction";
import { primaryColor } from "../store/slice/ContentSlice";
import { getReferenceNo, setReferenceNo } from '../utility/orderUtils';
import { notNull, notEmpty } from "../sharedFunctions";
import { current } from "@reduxjs/toolkit";

const { Title } = Typography;

const CheckOut = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
  const [quote, setQuote] = useState(null);
  const [code, setCode] = useState(null);
  const location = useLocation()
  const [formStep, setFormStep] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  });
  const primaryColorValue = useSelector(primaryColor);
  const [activeAccordionKey, setActiveAccordionKey] = useState("0");
  const [activePanel, setActivePanel] = useState(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const [userFinished, setUserFinished] = useState(false);
  const [paymentReady, setPaymentReady] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const medicalPatient = sessionStorage.getItem("menuType") === "Medical";
  const currentQuote = useSelector((store) => store?.quote?.info?.quote);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };
  const reviewItem = useSelector(
    (store) => store?.quote?.info?.quote?.items
  );
  const reference_no = getReferenceNo(venue_id);
  const handleNext = () => setActivePanel(activePanel + 1);

  useEffect(() => {
    if (quote && reference_no) {
      setReferenceNo(venue_id, quote?.reference_no);
    }
    if (notEmpty(currentQuote?.customer?.first_name) && notEmpty(currentQuote?.customer?.last_name) && notEmpty(currentQuote?.customer?.email) && notEmpty(currentQuote?.customer?.telephone)) {
      setUserFinished(true)
    }else{
      setUserFinished(false)
    }
    if (notNull(currentQuote?.payment?.requested_payment_method)) {
      if(currentQuote?.payment?.requested_payment_method === 'posabit_pay'){
        if(notNull(code)) {setPaymentReady(true)}
        else {setPaymentReady(false)}
      }else {setPaymentReady(true)}
    }
    if(!loaded && notNull(currentQuote)){
      setLoaded(true)
    }
  }, [currentQuote]);

  useEffect(() => {
    const payload = {reference_no: reference_no}
    dispatch(fetchQuoteCartInfoApi(reference_no, payload))
    setQuote(currentQuote)
  },[])

  useEffect(() => {
    const getCodeFromUrl = () => {
      const searchParams = new URLSearchParams(location.search);
      const codeFromUrl = searchParams.get('code');

      if (codeFromUrl) {
        setCode(codeFromUrl);
        setActivePanel(3);
      }
    };
    getCodeFromUrl();
  }, [location.search]);


  const panelStyle = {
    backgroundColor: "#fff",
    // marginBottom: 24,
  }

  const loadingBody = <>
    <Skeleton active />
  </>

  const panels = [
    {
      label: <Space style={{width: '100%'}} onClick={() => setActivePanel(1)}>
          <Button
            shape='circle'
            size='large'
            type='text'
            onClick={() => setActivePanel(1)}
            style={{boxShadow: Constant.BOX_SHADOW}}
            icon={<i style={{color: primaryColorValue}} className="fa fa-info"/>}
          />
          <Title style={{marginBottom: 0}} level={4}>{Constant.REVIEW}</Title>
        </Space>,
      key: 1,
      style: panelStyle,
      extra: <Avatar style={{backgroundColor: 'white'}} icon={<i style={{color: 'green'}} className="fa fa-check"/>} />,
      children: (loaded ?  <Review handleClick={handleNext} setActiveAccordionKey={setActiveAccordionKey}/> : loadingBody),
    },
    {
      label: <Space style={{width: '100%'}} onClick={() => setActivePanel(2)}>
          <Button
            shape='circle'
            size='large'
            type='text'
            onClick={() => setActivePanel(2)}
            style={{boxShadow: Constant.BOX_SHADOW}}
            icon={<i style={{color: primaryColorValue}} className="fa fa-credit-card"/>}
          />
          <Title style={{marginBottom: 0}} level={4}>{medicalPatient ? Constant.ENTER_PATIENT : Constant.ENTER_CUSTOMER}</Title>
        </Space>,
      key: 2,
      style: panelStyle,
      extra: <Avatar style={{backgroundColor: 'white'}} icon={userFinished ?
      <i style={{color: 'green'}} className="fa fa-check"/>
      : <i style={{color: 'gold'}} className="fa fa-exclamation-circle" />
    } />,
      children: loaded ? <UserInformation handleClick={handleNext} /> : loadingBody,
    },
    {
      label: <Space style={{width: '100%'}} onClick={() => setActivePanel(3)}>
          <Button
            shape='circle'
            size='large'
            type='text'
            onClick={() => setActivePanel(3)}
            style={{boxShadow: Constant.BOX_SHADOW}}
            icon={<i style={{color: primaryColorValue}} className="fa fa-edit"/>}
          />
          <Title style={{marginBottom: 0}} level={4}>{Constant.PAYMENT_METHOD}</Title>
        </Space>,
      key: 3,
      style: panelStyle,
      extra: <Avatar style={{backgroundColor: 'white'}} icon={paymentReady ?
        <i style={{color: 'green'}} className="fa fa-check"/>
        : <i style={{color: 'gold'}} className="fa fa-exclamation-circle" />
      } />,
      children: loaded ? <Payment handleClick={handleNext} step={formStep} setFormStep={setFormStep} /> : loadingBody,
    }
  ];

  // Conditionally add UserInformation and Payment components based on reviewItem length

  const showAdditionalComponents = reviewItem?.length >= 1;


  return (
    <>

    <div className="App payemnt_checkout_page">
      <div className="container">
        <nav aria-label="breadcrumb">
          <div className="back-btn">
            <Link onClick={() => navigate(-1)}>
              <i className="fa fa-arrow-left"/> Back
            </Link>
          </div>
        </nav>
        <Collapse
          size='large'
          items={panels}
          activeKey={activePanel}
          accordion
          expandIconPosition="end"
          style={{marginTop: 20}}
        />
        <div style={{marginTop: 15}}>
          <div className="">
            {showAdditionalComponents && loaded && (
              <ProcessToCheckOut formStep={formStep} />
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CheckOut;
