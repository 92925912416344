
import React,{useState} from 'react';

const StaticBanner = ({component}) => {
  const {id, url, text_color, background_color, details} = component;
  const [showStaticBanner,setShowStaticBanner] = useState(true)
  if (!showStaticBanner) return null;
  return (
    <div
      style={{
        backgroundColor: background_color,
        color: text_color,
      }}
      href={url}  
      className="top_header"
      key={id}
    >
    {showStaticBanner?<div>
      <div dangerouslySetInnerHTML={{ __html: details }} 
        style={{
          textAlign: "center",
          paddingRight: "25px",
          paddingLeft: "25px",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
        key={id}
      />
      <i className="fa fa-close" onClick={()=>setShowStaticBanner(!showStaticBanner)}/>
    </div>:null}
    </div>
  )
}

export default StaticBanner;

