import React from "react"

const Footer = () => {
  return (
    <footer className="text-center mt-4 pb-4">
      
      <img className="footer-img" src={window.location.origin + "/images/Powered by POSaBIT.png"} alt='footer img' />
    </footer>
  )
}

export default Footer
