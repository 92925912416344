import { Button, Input } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ReactCardFlip from "react-card-flip";
import { parseFiltersFromQueryParams } from "../utility/productUtils";


const Search = ({ setIsActive, handleClick }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [queryParameters] = useSearchParams();
  const primary_color = useSelector((state) => state?.content?.contentData?.menu?.primary_color);
  const filterKey = useSelector((state) => state?.content?.contentData?.filters) || [];
  const [isFlipped, setIsFlipped] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    const searchedString = queryParameters.get("search") || "";
    setSearchTerm(searchedString);
    setIsFlipped(searchedString.length > 0);
  }, [queryParameters]);

  const handleSearchInput = (e) => {
    let searched_string = searchTerm;
    let searched_string_length = searched_string?.length;

    const queryParams = new URLSearchParams(queryParameters);

    if (searched_string?.length === 0) {
      queryParams.delete("search");
    }
    else if (searched_string?.length > 2) {
      queryParams.set("search", searched_string);
    }


    // const brandFilter = queryParameters.get("brands");
    // const categoryFilter = queryParameters.get("categories");
    // const flavorFilter = queryParameters.get("flavors");
    // const effectFilter = queryParameters.get("effects");


    // if (brandFilter) {
    //   queryParams.set("brands", brandFilter);
    // }

    // if (categoryFilter) {
    //   queryParams.set("categories", categoryFilter); // Set category filter in URL
    // }

    // if (flavorFilter) {
    //   queryParams.set("flavors", flavorFilter); // Set flavor filter in URL
    // }

    // if (effectFilter) {
    //   queryParams.set("effects", effectFilter); // Set effect filter in URL
    // }

    if (searched_string_length > 2 || queryParams.size > 0) {
      navigate(`/products?${queryParams.toString()}`,  {state: {filters: parseFiltersFromQueryParams(queryParams, filterKey.map((filter) => filter.name))}});
    }
    else if (searched_string_length <=2 && searched_string_length > 0) {
      alert("Please enter at least 3 characters to search");
    }
    else if (searched_string_length === 0) {
      navigate("/");
    }
  };

  const cancelSearch = (e) => {
    setSearchTerm("");
    setIsFlipped(false);

    const queryParams = new URLSearchParams(queryParameters);
    queryParams.delete("search");

    if (queryParams.size > 0) {
      navigate(`/products?${queryParams.toString()}`,  {state: {filters: parseFiltersFromQueryParams(queryParams, filterKey.map((filter) => filter.name))}});
    } else {
      navigate("/");
    }
  };

  return (
    <div className=""
      // style={{ backgroundColor: `${primary_color}` }}
    >
      <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
        <Button
          type="text"
          size='large'
          shape='circle'
          icon={<i className="fa fa-search"/>}
          style={{ color: `${primary_color}` }}
          onClick={() => {
            setIsFlipped(true)
            inputRef.current && inputRef.current.focus();
          }}
          onBlur={() => {
            if(searchTerm.length > 0) {setIsFlipped(true)}
          }}
        />
        <Input
          type="search" 
          ref={inputRef}
          placeholder="Search"
          onClick={(e) => e.stopPropagation()}
          variant='filled'
          onBlur={() => {
            if(searchTerm.length === 0) {setIsFlipped(false)}
          
          }}
          size='large'
          allowClear={{
            clearIcon: 
              <i 
                className="fa fa-times" 
                onClick={(e) => cancelSearch(e)}
                style={{ color: 'grey' }} 
              />,
            }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            borderRadius: '50px',
            width: isMobile ? '80%' : '150%',
            fontSize: '16px'
          }}
          onPressEnter={() => handleSearchInput()}
          onKeyDown={(e) => {
            if(e.key === 'Return') {
              setIsFlipped(false)
            }
          }}
        />
      </ReactCardFlip>
    </div>
  );
};

export default Search;
