import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useNavigate } from "react-router-dom";
import * as Constant from "../Constants";
import QuoteBreakdown from "./QuoteBreakdown";
import { Button, Flex, Typography, Input } from 'antd'
import { notNull } from "../sharedFunctions";
import { useDispatch } from "react-redux";
import { putQuotesUpdateItemsApi } from "../store/InitializeQuotesPostAPI";
import { getReferenceNo } from "../utility/orderUtils";

const { Text } = Typography;
const { TextArea } = Input;

const Review = ({handleClick}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { product_types, strains, menu_items } = product_data;
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate("/");
  };
  const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
  const quote = useSelector(store => store?.quote?.info?.quote);
  const [notes, setNotes] = useState('');

  const items = useSelector(
    (store) => store?.quote?.info?.quote?.items
  );
  const reference_no = getReferenceNo(venue_id);


  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlug = slugId;

  useEffect(() => {
    if (notNull(quote)) {
      setNotes(quote?.notes || '');
    }
  }, [quote]);

  const handleNotes = () => {
    const slugId = sessionStorage.getItem("selectedVenue") || defaultSlug;
    dispatch(
      putQuotesUpdateItemsApi({
        slug: slugId,
        reference_no,
        quote: {
          fulfillment: {
            notes: notes,
          }
        },
      })
    );
  }



  return (
    <>
      {items?.length === 0 ? (
        <div className="empty_cart text-center container">
          <img src="./images/Group 778.png" alt='empty cart'/>
          <button className="btn btn-info mt-4 w-25" onClick={navigateHome}>
            {Constant.RETURN_TO_SHOPPING}
          </button>
        </div>
      ) : (
        <div className="">
          <div className="" style={{padding:15}}>
            <QuoteBreakdown />
            <Text strong>Notes</Text>
            <TextArea
              rows={4}
              placeholder="Add a note to your order"
              value={notes}
              style={{marginBottom: 15}}
              onChange ={(e) => setNotes(e.target.value)}
              onBlur={handleNotes}
            />
            <Flex justify="flex-end">
              <Button
                type='primary'
                size='large'
                onClick={handleClick}
                >
                {Constant.CONTINUE}
              </Button>
            </Flex>
          </div>
        </div>
      )}
    </>
  );
};

export default Review;
