import React from 'react';
import { useSelector } from 'react-redux';
import { Tag } from 'antd';
import { notEmpty, notNull } from '../sharedFunctions';

export default function TestResult ({type, item}) {
  const testType = type?.includes(' total') ? `total_${type?.replace(' total', '')}` : type;
  const rangeDisplay = useSelector((state) => state?.content?.contentData?.menu?.range_display_test_results);
  if(!notEmpty(item?.test_results?.[testType]) || !notNull(item?.test_results?.[testType]?.current)) {return null}
  if( testType?.includes('cbd') && (item?.test_results?.[testType] === null || item?.test_results?.[testType]?.current === 0)) {return null}

  const displayResult = (amount) => {
    const result = item?.test_results?.[testType]?.[amount]
    if (result === '<LOQ') { return result }
    else if(result > 0 && result < 0.01) {return result?.toFixed(3)}
    else if(result < 1 && result >= 0.01) {return result?.toFixed(2)}
    else{return result?.toFixed(0)}
  }
  const tag = {
    label: testType?.replace(/_/g, ' ').toUpperCase(),
    result: displayResult('current'),
    low: displayResult('low'),
    high: displayResult('high'),
    unit: item?.test_results?.[testType]?.unit_type,
  }

  return(
    <Tag
      bordered={true}
      style={{
        borderRadius: "10px",
        // padding: "5px",
        // fontSize: "12px",
        // fontWeight: "500",
        color: "grey",
        marginBottom: "3px",
        // backgroundColor: "#f0f0f0",
        // margin: "0 5px 5px 0",
      }}
    >
      {testType?.replace(/_/g, ' ').toUpperCase()}:{" "}
      {rangeDisplay && tag?.low !== tag?.high ?
        `${tag?.low}${tag.unit}-${tag?.high}${tag?.unit}` :
        tag?.result + tag?.unit
      }
    </Tag>
  )
}
